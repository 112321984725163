import {
    Addon,
    MenuProduct,
    MenuBundleProduct,
    Modification,
    Modifications,
    SelectedBundleProductItem,
    ServiceFee,
    OrderType,
    EatingPreference,
    OnlineEatingPreference,
    VatRateAmount,
    CartProduct
} from "Types";
import { roundToTwo } from "NumberUtils";
import { isMenuBundleProduct, isVariablePriceType } from "ProductUtils";

/***********************
 * Addons
 ***********************/
export const calculateTotalAddonPrice = ({ quantity, price }: Addon) => quantity * price;

export const calculateTotalAddonsPrice = (addons: Addon[]) => {
    if (!addons) {
        return 0;
    }
    return addons.reduce<number>((totalAddonsPrice, addon) => calculateTotalAddonPrice(addon) + totalAddonsPrice, 0);
};

const calculateBundleAddonPrice = (selectedBundleProductItems: SelectedBundleProductItem[] | null) => {
    if (!selectedBundleProductItems || selectedBundleProductItems.length === 0) {
        return 0;
    }

    return selectedBundleProductItems.reduce((total, selectedBundleItem) => {
        return total + calculateTotalAddonsPrice(selectedBundleItem.addons);
    }, 0);
};

/***********************
 * Modifications
 ***********************/
export const calculateTotalModPrice = (modifications: Modifications | null) => {
    if (!modifications) {
        return 0;
    }

    const sizes: Modification = modifications.sizes as Modification;
    const flavours: Modification = modifications.flavours as Modification;
    const sizePrice = sizes && sizes.length === undefined ? sizes.addonPrice : 0;
    const flavourPrice = flavours && flavours.length === undefined ? flavours.addonPrice : 0;
    return (!!sizePrice ? sizePrice : 0) + (!!flavourPrice ? flavourPrice : 0);
};

// this function takes care of array modifications
export const calculateModificationsPrice = (modifications: Modifications | null): number => {
    if (!modifications) {
        return 0;
    }

    const { sizes, flavours } = modifications;
    const sizePrice = Array.isArray(sizes)
        ? sizes.reduce((total, size) => total + (size.addonPrice || 0), 0)
        : sizes?.addonPrice || 0;

    const flavourPrice = Array.isArray(flavours)
        ? flavours.reduce((total, flavour) => total + (flavour.addonPrice || 0), 0)
        : flavours?.addonPrice || 0;
    return sizePrice + flavourPrice;
};

export const calculateBundleModsPrice = (selectedBundleProductItems: SelectedBundleProductItem[] | null) => {
    if (!selectedBundleProductItems || selectedBundleProductItems.length === 0) {
        return 0;
    }

    return selectedBundleProductItems.reduce((total, selectedBundleItem) => {
        return total + calculateTotalModPrice(selectedBundleItem.modifications || null);
    }, 0);
};

const getModifiedBasePriceFromModifications = (selectedModifications: Modifications | null) => {
    if (!selectedModifications) {
        return 0;
    }

    const sizes = selectedModifications.sizes as Modification;
    const flavours = selectedModifications.flavours as Modification;
    if (sizes && sizes.price > 0) {
        return sizes.price;
    } else if (flavours && flavours.price > 0) {
        return flavours.price;
    }
    return 0;
};

/***********************
 * Menu Products
 ***********************/
// TODO: Express- Port code from priceUtils : getMenuProductPriceText
// Write test
export const getMenuProductPrice = (menuProduct: MenuProduct) => {
    return menuProduct.price ? menuProduct.price : (menuProduct as MenuProduct).refProduct.defaultPrice;
};

// TODO: Express- Port code from priceUtils : getMenuBundleProductPriceText
// Write test
export const getMenuBundleProductPrice = (menuBundleProduct: MenuBundleProduct) => {
    return menuBundleProduct.price
        ? menuBundleProduct.price
        : (menuBundleProduct as MenuBundleProduct).refBundleProduct.defaultPrice;
};

// formally price.js : getPrice()
export const calculateMenuBundleProductPrice = (
    menuBundleProduct: MenuBundleProduct,
    selectedBundleItems: SelectedBundleProductItem[] | null,
    addons: Addon[] | null,
    weight: number | null
) => {
    const hasVariablePrice = isVariablePriceType(menuBundleProduct.refBundleProduct.priceType);
    const addonsPrice: number = addons ? calculateTotalAddonsPrice(addons) : 0;
    const menuBundleAddonPrice = calculateBundleAddonPrice(selectedBundleItems);
    const menuBundleModsPrice = calculateBundleModsPrice(selectedBundleItems);
    const menuBundleBasePrice = getMenuBundleProductPrice(menuBundleProduct);
    if (hasVariablePrice && !!weight) {
        return menuBundleBasePrice * weight + menuBundleModsPrice + menuBundleAddonPrice + addonsPrice;
    } else {
        return menuBundleBasePrice + menuBundleModsPrice + menuBundleAddonPrice + addonsPrice;
    }
};
export const calculateMenuProductPrice = (
    menuProduct: MenuProduct,
    selectedModifications: Modifications | null,
    addons: Addon[] | null,
    weight: number | null
) => {
    const hasVariablePrice = isVariablePriceType(menuProduct.refProduct.priceType);
    const addonsPrice: number = addons ? calculateTotalAddonsPrice(addons) : 0;
    const modificationAddonPrice = calculateTotalModPrice(selectedModifications);

    // Case #1: Modification overwrites base product price
    const modifiedBasePrice = getModifiedBasePriceFromModifications(selectedModifications);
    if (modifiedBasePrice > 0) {
        // This replaces the base product price
        return modifiedBasePrice + modificationAddonPrice + addonsPrice;
    }

    // Case #1: Modification adds to base product price
    const productBasePrice = getMenuProductPrice(menuProduct);

    if (hasVariablePrice && !!weight) {
        /**
         *  Weight should only affect the base product price, not the addon prices
         * For instance, adding salad dressing (+5kr) should not be multiplied by 0.7 if the salad weighs 0.7kg
         */
        return productBasePrice * weight + modificationAddonPrice + addonsPrice;
    } else {
        return productBasePrice + modificationAddonPrice + addonsPrice;
    }
};

export const calculateMenuProductOrBundlePrice = (
    menuProductOrBundleProduct: MenuProduct | MenuBundleProduct,
    quantity: number,
    weight: number | null,
    selectedModifications: Modifications | null,
    selectedBundleItems: SelectedBundleProductItem[] | null,
    addons: Addon[] | null,
    updatedUnitPrice?: number | null | undefined
) => {
    const isBundle = isMenuBundleProduct(menuProductOrBundleProduct);
    if (isBundle) {
        const menuBundleProduct = menuProductOrBundleProduct as MenuBundleProduct;
        const productPrice = updatedUnitPrice
            ? updatedUnitPrice
            : calculateMenuBundleProductPrice(menuBundleProduct, selectedBundleItems, addons, weight);
        const totalPrice = roundToTwo(productPrice * quantity);
        const unitPrice = isVariablePriceType(menuBundleProduct.refBundleProduct.priceType) ? totalPrice : productPrice;
        const vatRate = menuBundleProduct.refBundleProduct.vatRate;
        const vatAmount = getVatAmount(vatRate, totalPrice);
        const totalNetPrice = roundToTwo(totalPrice - vatAmount);

        return {
            unitPrice,
            totalPrice,
            vatRate: vatRate,
            totalNetPrice
        };
    } else {
        // Not a bundle
        const menuProduct = menuProductOrBundleProduct as MenuProduct;

        /**
         * Multiplying the productprice by quantity makes sense if we include the addon
         * However, if we the productPrice (including the addon price) by weight, then we modify the addonPrice by the weight, which is wrong.
         */
        const productPrice = updatedUnitPrice
            ? updatedUnitPrice
            : calculateMenuProductPrice(menuProduct, selectedModifications, addons, weight);
        const totalPrice = roundToTwo(productPrice * quantity);
        const unitPrice = isVariablePriceType(menuProduct.refProduct.priceType) ? totalPrice : productPrice;
        const vatRate = menuProduct.refProduct.vatRate;
        const vatAmount = getVatAmount(vatRate, totalPrice);
        const totalNetPrice = roundToTwo(totalPrice - vatAmount);

        return {
            unitPrice,
            totalPrice,
            vatRate: vatRate,
            totalNetPrice
        };
    }
};

/***********************
 * VAT - RATE + Total have switched places from the old function
 ***********************/
export const getVatAmount = (rate: number, total: number) => {
    const vatRateDivider = 1 + rate / 100;
    return parseFloat((total - total / vatRateDivider).toFixed(2));
};

export const applyServiceFeeVatToVatRatesAndAmounts = (
    serviceFee: ServiceFee | undefined | null,
    vatRateAmounts: VatRateAmount[]
) => {
    if (!serviceFee) {
        return vatRateAmounts;
    }

    let vatRatesAndAmounts = [...vatRateAmounts];
    // Added service fee vat to summary
    if (!!serviceFee?.amount && !!serviceFee?.vatRate) {
        // Calculate service fee VAT
        const serviceFeeVATAmount = getVatAmount(serviceFee.vatRate, serviceFee.amount);

        // Find entry corresponding to the Service fee VAT
        const existingVatRateAmountEntry = vatRatesAndAmounts.find(
            vatRatesAndAmount => vatRatesAndAmount.vatRate == serviceFee.vatRate
        );

        if (!!existingVatRateAmountEntry) {
            // Update the entry with the new amount
            vatRatesAndAmounts = vatRatesAndAmounts.map(vatRatesAndAmountsElem => {
                if (serviceFee.vatRate == vatRatesAndAmountsElem.vatRate) {
                    return {
                        vatRate: serviceFee.vatRate,
                        amount: vatRatesAndAmountsElem.amount + serviceFeeVATAmount
                    };
                }
                return vatRatesAndAmountsElem;
            });
        } else {
            // Add an entry only for this vatRate if the array does not yet include the vatRate
            vatRatesAndAmounts.push({ vatRate: serviceFee.vatRate, amount: serviceFeeVATAmount });
        }
    }
    return vatRatesAndAmounts.filter(vatRateAmount => !!vatRateAmount.amount);
};

/** Note: This is in the test suite */
export const getVatAmountArrayForProducts = (cartProducts: CartProduct[]) => {
    const vatAmountMap = getVatAmountMapForProducts(cartProducts);

    return Object.entries(vatAmountMap).map(([key, value]) => {
        return { vatRate: +key, amount: value } as VatRateAmount;
    });
};

export const getVatAmountMapForProducts = (cartProducts: CartProduct[]) => {
    return cartProducts.reduce((tot, { orderProduct }) => {
        if (tot[orderProduct.vatRate]) {
            tot[orderProduct.vatRate] += getVatAmount(orderProduct.vatRate, orderProduct.totalPrice);
        } else {
            tot[orderProduct.vatRate] = getVatAmount(orderProduct.vatRate, orderProduct.totalPrice);
        }
        return tot;
    }, {} as Record<number, number>);
};

/***********************
 * FEES
 ***********************/

export const calculateDeliveryFee = (subTotal: number, minAmountFreeDelivery: number, deliveryFee: number) => {
    // No fee
    if (deliveryFee === 0) {
        return 0;
    }

    // Don't charge delivery on FREE amount orders
    if (subTotal === 0) {
        return 0;
    }

    // Undefined threshold, charge delivery fee
    if (minAmountFreeDelivery === 0) {
        return deliveryFee;
    }

    // Does not exceed threshold
    if (subTotal < minAmountFreeDelivery) {
        return deliveryFee;
    }

    // Exceeds min delivery amount threshold
    return 0;
};
