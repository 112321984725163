import * as Sentry from "@sentry/browser";
const redirectIssueProps = ["data.getShopByUrl", "getPublicShopById.getPublicShopById", "networkError.result.data"];

/** NOTE THIS FILE IS IN THE TEST SUITE  */

/**
 * [FUNCTION] check object has a property
 * @param obj
 * @param prop
 * @returns true or false
 */
export const hasProperty = (obj: Object, prop: string) => {
    return Reflect.has(obj, prop);
};

/**
 * [FUNCTION] get drilled down object values from redirect issues
 * @param json
 * @param value
 * @returns undefined or null
 * @description if undefined then it's not there if null it is there
 * @description uses Redirect isssue props
 */
export const checkNestedObject = (json: Object, value: string) => {
    const propDrill = value.split(".");
    return propDrill.reduce((obj: Object | undefined, prop: string) => {
        if (typeof obj === "object") {
            obj = hasProperty(obj, prop) ? Reflect.get(obj, prop) : undefined;
        }
        return obj;
    }, json);
};

const createMemoryExtra = () => {
    /** @ts-ignore */
    if (performance && performance.memory) {
        /** @ts-ignore */
        const { totalJSHeapSize, usedJSHeapSize, jsHeapSizeLimit } = performance.memory;
        const singleDecimalPercentage = (usedJSHeapSize / jsHeapSizeLimit) * 100;

        return {
            memoryInfo: {
                totalJSHeapSize,
                usedJSHeapSize,
                jsHeapSizeLimit,
                usage: `${singleDecimalPercentage.toFixed(2)}%`
            } as {
                totalJSHeapSize: number;
                usedJSHeapSize: number;
                jsHeapSizeLimit: number;
                usage: string;
            }
        };
    }

    return {};
};

const createNavigationTimingExtra = () => {
    if (performance && typeof performance.getEntriesByType === "function") {
        const navigationEntries = performance.getEntriesByType("navigation");
        if (navigationEntries && navigationEntries.length > 0) {
            const navigationTiming = navigationEntries[0] as PerformanceNavigationTiming;

            // Extract relevant timing properties
            /** ts-ignore */
            return {
                navigationTimings: {
                    domContentLoadedEventEnd: navigationTiming.domContentLoadedEventEnd,
                    domInteractive: navigationTiming.domInteractive,
                    loadEventEnd: navigationTiming.loadEventEnd,
                    responseEnd: navigationTiming.responseEnd,
                    responseStart: navigationTiming.responseStart,
                    fetchStart: navigationTiming.fetchStart,
                    connectStart: navigationTiming.connectStart,
                    connectEnd: navigationTiming.connectEnd
                }
            };
        }
    }

    return {};
};

export const attachedExtrasToSentryEvent = (event: Sentry.Event) => {
    const memoryExtra = createMemoryExtra();
    if (memoryExtra) {
        event.extra = {
            ...event.extra,
            ...memoryExtra
        };
    }

    const navigationTimingExtra = createNavigationTimingExtra();
    if (navigationTimingExtra) {
        event.extra = {
            ...event.extra,
            ...navigationTimingExtra
        };
    }

    return event;
};

/**
 * [FUNCTION] checks the sentry error before it sends, if the error has been set up not to be sent
 * will return a null if not will return the error
 * @param sentryError
 * @returns
 */
export const sentryErrorHelper = (sentryError: Sentry.Event) => {
    try {
        /**
         * According to https://github.com/qopla/fronty/pull/2203
         * This is designed to "Place some errors to be ignored in the initial start"
         */
        const extra = sentryError.extra;

        if (extra) {
            const { REDIRECT_REASONS } = extra;
            if (REDIRECT_REASONS) {
                let json;
                try {
                    // Parse redirect reasons to json
                    json = JSON.parse(REDIRECT_REASONS as string);
                } catch (error) {
                    console.error("Invalid JSON in REDIRECT_REASONS:", error);
                    return null;
                }
                const hasNullProp = redirectIssueProps.some(value => {
                    const drilled = checkNestedObject(json, value);
                    return drilled === null;
                });
                return hasNullProp ? null : sentryError;
            }
        }

        attachedExtrasToSentryEvent(sentryError);

        return sentryError;
    } catch (error) {
        return sentryError;
    }
};

// Create a hook which will be used in the App.tsx file
// This hook with periodically send memory reports to Sentry if above a certain threshold
export const useSentryMemoryReport = () => {
    // 0.9 was chosen because it is assumed that we could establish a few datapoints between 90% and 100% memory usage before
    // the browser crashes. This is a conservative estimate. This works well with the 1 minute interval.
    const MEMORY_USAGE_THRESHOLD = 0.9;
    const MEMORY_USAGE_REPORT_INTERVAL = 60 * 1000; // 1 minute

    const checkMemoryUsage = () => {
        const memoryExtra = createMemoryExtra();
        if (!memoryExtra?.memoryInfo) {
            return;
        }

        const navigationTimingExtra = createNavigationTimingExtra();

        const used = memoryExtra.memoryInfo.usedJSHeapSize;
        const total = memoryExtra.memoryInfo.jsHeapSizeLimit;

        const usageRatio = used / total;
        if (usageRatio > MEMORY_USAGE_THRESHOLD) {
            console.warn(`High memory usage detected: ${(usageRatio * 100).toFixed(2)}%`);
            Sentry.captureMessage(`High memory usage detected: ${(usageRatio * 100).toFixed(2)}%`, {
                level: Sentry.Severity.Warning,
                extra: {
                    ...memoryExtra,
                    ...navigationTimingExtra
                }
            });
        }
    };

    // Check memory usage every minute
    setInterval(checkMemoryUsage, MEMORY_USAGE_REPORT_INTERVAL);
};
