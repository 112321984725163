export const onScrollToSection = (id: string, refs: { [key: string]: any }, offset: number) => {
    const node = refs[id].current;

    node.scrollIntoView(true);

    const scrolledY = window.scrollY;

    if (scrolledY) {
        window.scroll(0, scrolledY - offset);
    }
};
