import CryptoJS from "crypto-js";

import { kagi, persistedDefaultValues } from "Constants";
import { allwedd } from "Utils";

export const defaultValues = persistedDefaultValues;

export const persistToStorage = (type, object) => {
    const encryptedObject = CryptoJS.AES.encrypt(JSON.stringify(object), kagi + allwedd).toString();
    sessionStorage.setItem(type, encryptedObject);
};

export const getPersistedObject = objectType => {
    try {
        const item = sessionStorage.getItem(objectType);
        if (item) {
            const bytes = CryptoJS.AES.decrypt(item, kagi + allwedd);
            const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedString);
        } else {
            return defaultValues[objectType];
        }
    } catch (err) {
        console.log("ERR", err);
        const item = sessionStorage.getItem(objectType);
        return item ? JSON.parse(item) : defaultValues[objectType];
    }
};
