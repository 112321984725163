
import { MenuPreviewProduct, ProductLibraryProductType, RefProduct, RefProductCategory } from "Types";

// This is the default category for products that don't have a category
// TODO: Consider if this needs to be translated later
export const PRODUCT_CATEGORY_UNCATEGORIZED = "uncategorized";

/**
 * This function returns an array of unique categories from an array of MenuPreviewProduct objects.
 * @param previewProducts The array of MenuPreviewProduct objects
 * @returns An array of unique categories
 */
export const getCategoriesFromPreviewProducts = (previewProducts: MenuPreviewProduct[] = []): string[] => {
	const categories = new Set<string>();
	previewProducts.forEach(product => {
		if (!product.category) {
			categories.add(PRODUCT_CATEGORY_UNCATEGORIZED);
			return;
		}

		categories.add(product.category);
	});
	return Array.from(categories);
}

/**
 * This function creates an array of RefProduct objects from an array of MenuPreviewProduct objects. Attaching the category to the product.
 * @param previewProducts  The array of MenuPreviewProduct objects
 * @param categories  The array of RefProductCategory objects. Assuming the categories are already created and the id exists.
 * @throws If a product doesn't have a category, this function will throw an error. Please show this to the user
 * @returns An array of RefProduct-like objects
 */
export const createInitialRefProductsFromPreviewProductsAndCategories = (previewProducts: MenuPreviewProduct[] = [], categories: RefProductCategory[] = []): ProductLibraryProductType[] | never => {
	const initialProducts: ProductLibraryProductType[] = [];
	previewProducts.forEach(product => {
		const productCategory = product.category || PRODUCT_CATEGORY_UNCATEGORIZED;

		// If the product doesn't have a category, the category creation prior to this has failed
		const category = categories.find(c => c.name === productCategory);
		if (!category) {
			// Show this message to the user
			throw new Error(`Category not found for product: ${product.title}`);
		}

		const refProduct: ProductLibraryProductType = {
			name: product.title,
			description: product.description,
			refProductCategoryId: category.id,
			companyId: category.companyId,
			vatRate: 12
		};
		refProduct.defaultPrice = product.price ? +product.price : 0;
		initialProducts.push(refProduct);
	});

	return initialProducts;
}