import { UserRoles } from "Constants";
import { AuthenticatedUser } from "Types";

export const hasRoles = (expectedRoles: UserRoles[]) => (userRoles: UserRoles[]) =>
    !!userRoles?.some(userRole => expectedRoles.includes(userRole));

export const isAtLeastAdmin = hasRoles([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]);
export const isSuperAdmin = hasRoles([UserRoles.ROLE_SUPER_ADMIN]);
export const isAtLeastCompanyAdmin = (user: AuthenticatedUser) =>
    isSuperAdmin(user.roles) || (isAtLeastAdmin(user.roles) && !user.shopIds.length);
export const isRootAdmin = (user: AuthenticatedUser) => isAtLeastAdmin(user.roles) && !user.shopIds.length;
export const isShopAdmin = (user: AuthenticatedUser) => isAtLeastAdmin(user.roles) && !!user.shopIds.length;
export const isUser = hasRoles([UserRoles.ROLE_USER]);
export const isQoplaSale = hasRoles([UserRoles.ROLE_QOPLA_SALE]);
export const isAccountingUser = hasRoles([UserRoles.ROLE_ACCOUNTING_USER]);
export const hasAccountingRights = hasRoles([UserRoles.ROLE_ADMIN, UserRoles.ROLE_ACCOUNTING_USER]);
export const isQoplaSupport = hasRoles([UserRoles.ROLE_QOPLA_SUPPORT]);
export const isSuperAdminOrQoplaSupport = (userRoles: UserRoles[]) =>
    isSuperAdmin(userRoles) || isQoplaSupport(userRoles);
export const isUmbrellaUser = hasRoles([UserRoles.ROLE_UMBRELLA_USER]);
export const isUmbrellaAdmin = hasRoles([UserRoles.ROLE_UMBRELLA_MULTI_COMPANY_ADMIN]);

export const isOnlyUser = (userRoles: UserRoles[]) => userRoles.length === 1 && isUser(userRoles);

export const authorizationBearerToken = (operationName: string): string => {
    let token = sessionStorage.getItem("jwtToken");
    return token && operationName !== "login" ? "Bearer " + token : "Bearer ";
};

export const isAuthorized = (userRoles: string[], allowedRoles?: string[]) => {
    if (allowedRoles === undefined) {
        return true;
    }
    const setUserRoles = new Set(userRoles);
    const setAllowedRoles = new Set(allowedRoles);
    let intersection = new Set([...setAllowedRoles].filter(allowedRole => setUserRoles.has(allowedRole)));
    return intersection.size > 0;
};

// WTF.  Srsly tho, what is this variable name.
export const allwedd = '(,m"+n,,7QAD%j_Q.NM5!6M#yFJgP*@Xz~Wq5}-D}J&&![-:eNZk"HM';

export const smallMobileScreen =
    "LbB(`&vAdg=_Ew85/L{hhALj!Vr3/G&4k8qq}?jrs3!nXZ,K6@92b,}y9U-e9H'&2CdA*U85WQ=h5r!AcLce>";
