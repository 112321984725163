import { Subscription, CompanySubscription } from "Types";

export const sortSubscriptions = (a: Subscription, b: Subscription) => {
    const defaultSortOrder = Number.MAX_SAFE_INTEGER;
    // Cant use `sortOrder || defaultSortIndex` here because sortOrder might be zero
    const aSortOrder = a.sortOrder == null ? defaultSortOrder : a.sortOrder;
    const bSortOrder = b.sortOrder == null ? defaultSortOrder : b.sortOrder;
    const sortOrderDiff = aSortOrder - bSortOrder;

    // Sort by name if sort indices are equivalent
    if (sortOrderDiff == 0) {
        return a.name > b.name ? 1 : -1;
    }

    return sortOrderDiff;
};

export const sortCompanySubscriptions = (a: CompanySubscription, b: CompanySubscription) => {
    const defaultSortOrder = Number.MAX_SAFE_INTEGER;
    // Cant use `sortOrder || defaultSortIndex` here because sortOrder might be zero
    const aSortOrder = a.subscription.sortOrder == null ? defaultSortOrder : a.subscription.sortOrder;
    const bSortOrder = b.subscription.sortOrder == null ? defaultSortOrder : b.subscription.sortOrder;
    const sortOrderDiff = aSortOrder - bSortOrder;

    // Sort by name if sort indices are equivalent
    if (sortOrderDiff == 0) {
        return a.subscription.name > b.subscription.name ? 1 : -1;
    }

    return sortOrderDiff;
};
