import flatMap from "lodash/flatMap";

import { EatingPreference, EatingOption, Menu, MenuProductCategory, OnlineEatingPreference, OnlineMenu, OnlineMenus } from "Types";

const getMenusByOnlineIds = (menus: Menu[], onlineMenuIds: string[]) =>
    menus.filter(menu => onlineMenuIds.includes(menu.id));

const hasMenu = (eatingOption: EatingOption) => (menus: Menu[] | OnlineMenus) =>
    menus.some((menu: Menu | OnlineMenu) => menu.eatingOptions.includes(eatingOption));
const hasCateringMenu = hasMenu(EatingOption.CATERING);

const getMenu = (eatingOption: EatingOption) => (menus: Menu[]) =>
    menus.find(menu => menu.eatingOptions.includes(eatingOption));
const getCateringMenu = getMenu(EatingOption.CATERING);
const getHomeDeliveryMenu = getMenu(EatingOption.HOME_DELIVERY);

const flatProductsFromCategories = (menuProductCategories: MenuProductCategory[]) => {
    return flatMap(menuProductCategories, ({ menuBundleProducts, menuProducts }) => [
        ...menuProducts,
        ...menuBundleProducts
    ]);
};

const getMenusByEatingOption = (menus: Menu[], eatingOption: EatingOption) =>
    menus.filter(menu => menu.eatingOptions.includes(eatingOption));

const getMenusFromEatingPreference = (
    menus: Menu[],
    eatingPreference: OnlineEatingPreference,
    shopEatingOptions: EatingPreference[]
) => {
    if (!!eatingPreference) {
        const _eatingPreference = isEatingPreferenceCatering(eatingPreference)
            ? EatingPreference.CATERING
            : eatingPreference;
        return menus.filter(({ eatingOptions }) => eatingOptions.includes(_eatingPreference as any));
    }
    return menus.filter(({ eatingOptions }) => eatingOptions.some(opt => shopEatingOptions.includes(opt as any)));
};

const isEatingPreferenceCatering = (eatingPreference: EatingPreference | "") => {
    if (!!eatingPreference) {
        return [EatingPreference.CATERING_DELIVERY, EatingPreference.CATERING_TAKE_AWAY].includes(eatingPreference);
    }
    return false;
};



export {
    getMenusByOnlineIds,
    hasCateringMenu,
    getCateringMenu,
    getHomeDeliveryMenu,
    flatProductsFromCategories,
    getMenusByEatingOption,
    getMenusFromEatingPreference,
    isEatingPreferenceCatering
};
