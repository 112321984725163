import { RefBundleProduct, RefProduct, PriceType, MenuProduct, MenuBundleProduct } from "Types";

export const isMenuBundleProduct = (product: MenuProduct | MenuBundleProduct): boolean => {
    if ((product as MenuBundleProduct).refBundleProduct) {
        return true;
    }
    return false;
};

export const isBundleProduct = (product: RefProduct | RefBundleProduct): boolean => {
    if ((product as RefBundleProduct).bundleProductCategories) {
        return true;
    }
    return false;
};

export const isVariablePriceType = (priceType: PriceType): boolean => {
    if ([PriceType.PRICE_PER_UNIT].includes(priceType)) {
        return false;
    }
    if ([PriceType.PRICE_PER_GRAM, PriceType.PRICE_PER_HECTOGRAM, PriceType.PRICE_PER_KILOGRAM].includes(priceType)) {
        return true;
    }
    return false;
};
