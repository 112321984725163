/**
 * @module productHelpers
 *
 */

import { toMoment } from "Utils";
import { DAYS } from "Constants";
import { getBundleModAddonPriceText } from "../admin/utils/getPriceText";

export const stripAddons = addons => (!!addons ? addons.map(({ groupId, ...addon }) => addon) : []);

export const isMenuBundleProduct = product => {
    return !!product.refBundleProduct;
};

export const hasModifications = menuProduct => {
    return !!menuProduct.refProduct.modifications || !!menuProduct.modifications;
};

export const getMenuProductModificationsToUse = menuProduct => {
    return !!menuProduct.modifications ? menuProduct.modifications : menuProduct.refProduct.modifications;
};

/**
 * @param  {array} allProds
 * @param {object} pickup
 * @returns {array}
 */

export const activeHoursProducts = (allProds, pickup = null) => {
    const day =
        pickup && pickup.date !== "" ? toMoment(pickup.date).format("dddd").toUpperCase() : DAYS[new Date().getDay()];

    return allProds
        .map(prod => {
            return {
                ...prod,
                activeHours: prod.activeHours ? prod.activeHours : []
            };
        })
        .filter(
            product => product.activeHours.length === 0 || product.activeHours.some(item => item.dayOfWeek === day)
        );
};

/**
 * @param  {string} productId
 * @param  {Set} outOfStockSet
 * @returns {Boolean}
 */
export const isOutOfStock = (productId, outOfStockSet) => {
    if (!outOfStockSet) return false;
    return outOfStockSet.has(productId);
};

/**
 * @param  {string} productId
 * @param  {Set} hideFromStockSet
 * @returns {Boolean}
 */
export const isHideFromStock = (productId, hideFromStockSet) => {
    if (!hideFromStockSet) return false;
    return hideFromStockSet.has(productId);
};


/**
 * @method
 * @param {object} product - A product
 * @param {object} modifications - Modifications
 * @returns {array} - An array of combinations from the modifications
 */
export const makeProductsFromModifications = (product, { sizes, flavours }) => {
    const regex = new RegExp(product.name, "gi");

    const priceText = (size, flavour) => {
        const modsArray = { sizes: size ? [size] : [], flavours: flavour ? [flavour] : [] };
        return getBundleModAddonPriceText(modsArray);
    };

    if (sizes.length > 0 && flavours.length > 0) {
        return _.flatMap(sizes, size => {
            return flavours.map(flavour => {
                const sizeWithoutName = size.name.replace(regex, "");
                return {
                    ...product,
                    priceText: priceText(size, flavour),
                    displayName: `${sizeWithoutName} ${flavour.name}`.trim(),
                    modifications: { sizes: size, flavours: flavour }
                };
            });
        });
    } else if (sizes.length <= 1 && flavours.length <= 1) {
        const size = sizes.length > 0 ? sizes[0] : null;
        const flavour = flavours.length > 0 ? flavours[0] : null;
        return [
            {
                ...product,
                priceText: priceText(size, flavour),
                displayName: `${size ? size.name + " " : ""}${flavour ? flavour.name + " " : ""}${product.name}`.trim(),
                modifications: {
                    sizes: size,
                    flavours: flavour
                }
            }
        ];
    } else if (sizes.length > 0 && flavours.length === 0) {
        return _.flatMap(sizes, size => {
            return {
                ...product,
                displayName: `${size.name} ${product.name}`.trim(),
                priceText: priceText(size, null),
                modifications: { sizes: size, flavours: null }
            };
        });
    } else if (sizes.length === 0 && flavours.length > 0) {
        return _.flatMap(flavours, flavour => {
            return {
                ...product,
                displayName: `${flavour.name} ${product.name}`.trim(),
                priceText: priceText(null, flavour),
                modifications: { sizes: null, flavours: flavour }
            };
        });
    }
};

/**
 * Returns refId from cartProduct
 * @method
 * @param {object} cartProduct
 * @returns {string} The refId
 *
 */
export const getRefIdFromCartProduct = cartProduct => {
    return cartProduct?.orderProduct?.refProductId || cartProduct?.orderProduct?.refBundleProductId;
};

/**
 * @method
 * @param {array} products - Array of products
 * @returns {object} - An object with refIds and quantity
 */
export const productsIdQuantity = products => {
    return products.reduce((acc, curr) => {
        const { orderProduct } = curr;
        const prodId = orderProduct.refProductId || orderProduct.refBundleProductId;
        const quantity = acc[prodId] ? acc[prodId] + orderProduct.quantity : orderProduct.quantity;

        acc[prodId] = quantity;
        return acc;
    }, {});
};

export const getBundleItemNameFromMods = bundleItem => {
    const { modifications } = bundleItem;
    const modObject =
        modifications && (modifications.sizes || modifications.flavours)
            ? {
                  sizes: modifications.sizes ? [modifications.sizes] : [],
                  flavours: modifications.flavours ? [modifications.flavours] : []
              }
            : null;
    let newName = bundleItem.name;
    if (modObject) {
        const productNameWithMods = makeProductsFromModifications(bundleItem, modObject);
        newName =
            productNameWithMods && productNameWithMods[0].displayName
                ? productNameWithMods[0].displayName
                : productNameWithMods[0].name;
    }
    return newName;
};

/**
 * Checks if all cartProducts are completed
 * @method
 * @param {array} cartProducts - Array of cartProducts
 * @returns {boolean}
 *
 */
export const isCartFinished = cartProducts =>
    cartProducts &&
    cartProducts
        .filter(cartProduct => !!cartProduct?.orderProduct?.refBundleProductId) // Only check bundle products
        .every(cartProduct => cartProduct.isFinished);

/**
 * Converts CartProducts to OrderProducts.  Prepares to send to backend by removing fields that only Fronty uses
 * @method
 * @param {array} cartProducts - Array of cartProducts
 * @returns {array} Array of orderProducts
 *
 */
export const convertCartProductsToOrderProducts = cartProducts => {
    const cartOrderProducts = cartProducts.map(cartProduct => cartProduct.orderProduct);
    return convertExtendedOrderProductsToOrderProducts(cartOrderProducts);
};

export const stripFrontendOrderProductFields = orderProduct => {
    const { isOpenProduct, isStockTracked, ..._orderProduct } = orderProduct;
    if (!!_orderProduct.selectedBundleProductItems) {
        return {
            ..._orderProduct,
            addons: stripAddons(_orderProduct.addons),
            selectedBundleProductItems: _orderProduct.selectedBundleProductItems.map(
                ({ modificationsToUse, ...bundleItem }) => ({
                    ...bundleItem,
                    addons: stripAddons(bundleItem.addons)
                })
            )
        };
    } else {
        return {
            ..._orderProduct,
            addons: stripAddons(_orderProduct.addons)
        };
    }
};

/**
 * Removes fields that Fronty uses from extended OrderProducts
 * @method
 * @param {array} extendedOrderProducts - Array of extended OrderProducts
 * @returns {array} Array of orderProducts
 *
 */
export const convertExtendedOrderProductsToOrderProducts = extendedOrderProducts => {
    return extendedOrderProducts.map(extendedOrderProduct => stripFrontendOrderProductFields(extendedOrderProduct));
};

/**
 * Function to change back discount ids to orignal if any applied discount has been used
 * If the applied discount is applied on Each product - a different id is used in the cloning of that id
 * for displaying on the Kassan - easiest option as could end up having to change in a lot of places
 * @param {OrderWindow} currentOrderWindow
 */
export const changeAppliedDiscountIdsToOriginal = orderWindow => {
    const { discount, cartProducts } = orderWindow;
    if (discount && discount?.orgId) {
        const updatedCartProducts = cartProducts.reduce((cartProducts, cartProduct) => {
            const { orderProduct } = cartProduct;
            if (orderProduct.combinedDiscounts) {
                const hasAppliedDiscounts = orderProduct.combinedDiscounts.some(
                    value => value.discountId === discount.id
                );
                if (hasAppliedDiscounts) {
                    const updatedCombinedDiscounts = orderProduct.combinedDiscounts.map(value => {
                        return value.discountId === discount.id ? { ...value, discountId: discount.orgId } : value;
                    });
                    const updatedCartProduct = {
                        ...cartProduct,
                        orderProduct: {
                            ...cartProduct.orderProduct,
                            combinedDiscounts: updatedCombinedDiscounts
                        }
                    };
                    cartProducts = [...cartProducts, updatedCartProduct];
                } else {
                    cartProducts = [...cartProducts, cartProduct];
                }
            } else {
                cartProducts = [...cartProducts, cartProduct];
            }
            return cartProducts;
        }, []);
        const { orgId, appliedDiscountType, ...updatedDiscount } = { ...discount, id: discount.orgId };
        const updatedOrderWindow = {
            ...orderWindow,
            cartProducts: updatedCartProducts,
            discount: updatedDiscount
        };
        return updatedOrderWindow;
    }
    return orderWindow;
};

export const addBundleProductQuantity = (bundleItemsRefQuantityHashMap, bundleProductQuantity) => {
    return Object.entries(bundleItemsRefQuantityHashMap).reduce((acc, [refId, quantity]) => {
        acc[refId] = quantity * bundleProductQuantity;
        return acc;
    }, {});
};

export const getBundleItemRefQuantityMap = bundleProductItems => {
    return bundleProductItems.reduce((acc, { refProductId }) => {
        if (!!refProductId) {
            if (!acc[refProductId]) {
                acc[refProductId] = 1;
            } else {
                acc[refProductId] += 1;
            }
        }
        return acc;
    }, {});
};

export const isBundleCartProductIncrementPossible = (
    cartProduct,
    productsStock,
    quantityToCheck = "remainingQuantity",
    productQuantity
) => {
    const { orderProduct } = cartProduct;

    // Only get chosen bundle items.  Unchosen bundle items have no ref product id
    const selectedBundleProductItems = (orderProduct.selectedBundleProductItems || []).filter(
        selectedBundleProductItem => !!selectedBundleProductItem.refProductId
    );

    const bundleItemRefQuantities = addBundleProductQuantity(
        getBundleItemRefQuantityMap(selectedBundleProductItems),
        productQuantity
    );

    const bundleItemRefQuantitiesArr = Object.entries(bundleItemRefQuantities);

    // Quick check if any of orderProduct's bundleItems are out of stock
    const isAnyBundleItemsOutOfStock = bundleItemRefQuantitiesArr.some(
        ([refId]) => productsStock.get(refId)?.[quantityToCheck] <= 0
    );

    if (isAnyBundleItemsOutOfStock) {
        return false;
    } else {
        // Checking if we have enough bundleItem's for an incremental
        const hasEnoughForIncremental = !bundleItemRefQuantitiesArr.some(([refId, usedQuantity]) => {
            const remainingBundleItemStock = productsStock.get(refId)?.[[quantityToCheck]];
            const quantityRequiredForIncrement = usedQuantity / productQuantity;

            if (remainingBundleItemStock < quantityRequiredForIncrement) {
                return true;
            } else {
                return false;
            }
        });

        return hasEnoughForIncremental;
    }
};

export const isMenuCartProductIncrementPossible = (
    cartProduct,
    productsStock,
    quantityToCheck = "remainingQuantity"
) => {
    const refId = getRefIdFromCartProduct(cartProduct);
    const remainingProductStock = productsStock.get(refId)?.[quantityToCheck];

    return remainingProductStock !== 0;
};

export const isCartProductOutOfStock = (
    cartProduct,
    productsStock,
    quantityToCheck = "remainingQuantity",
    productQuantity
) => {
    const isBundleProduct = !!cartProduct.menuBundleProduct;
    if (isBundleProduct) {
        return !isBundleCartProductIncrementPossible(cartProduct, productsStock, quantityToCheck, productQuantity);
    } else {
        return !isMenuCartProductIncrementPossible(cartProduct, productsStock, quantityToCheck);
    }
};

export const isCartProductIncrementDisabled = (
    cartProduct,
    productsStock,
    quantityToCheck = "remainingQuantity",
    productQuantity
) => {
    const isFreeSubscriptionProduct = cartProduct?.fixedDiscount?.subscriptionProductMeta?.percentageDiscount === 100;
    if (isFreeSubscriptionProduct) {
        return true;
    }

    if (cartProduct?.orderProduct?.isStockTracked) {
        return isCartProductOutOfStock(cartProduct, productsStock, quantityToCheck, productQuantity);
    }

    return false;
};

// If we want 'showWhenStockIs' to be configurable in future we can pass in the number
export const shouldDisplayStockStatusText = (stockQuantity, showWhenStockIs = 5) =>
    stockQuantity && !isNaN(stockQuantity) ? stockQuantity <= showWhenStockIs : false;

export const isProductOutOfStockInventory = quantity => typeof quantity === "number" && quantity <= 0;

export const getProductCartQuantity = (productAmount, cartProductExists, hasBeenSearched) => {
    let quantity;

    const productAmountNumber = Number(productAmount);

    if (hasBeenSearched && !cartProductExists) {
        return (quantity = 1);
    } else if (cartProductExists) {
        if (productAmount === "") quantity = cartProductExists.orderProduct.quantity + 1;
        else quantity = cartProductExists.orderProduct.quantity + productAmountNumber;
    } else if (productAmount !== "") {
        quantity = productAmountNumber;
    } else {
        quantity = 1;
    }

    return quantity;
};
