import { Children, isValidElement } from "react";
import { ResponsiveValue } from "styled-system";

const getValidChildren = (children: React.ReactNode) =>
    Children.toArray(children).filter(child => isValidElement(child));

const countToColumns = (count: ResponsiveValue<number>): ResponsiveValue<string> | null => {
    if (Array.isArray(count)) {
        return count.map(c => countToColumns(c) as string);
    }

    if (count !== null && typeof count === "object" && Object.keys(count).length > 0) {
        const acc: Record<string | number, string> = {};

        for (let key in count) {
            acc[key] = `repeat(${count[key]}, 1fr)`;
        }
        return acc;
    }

    if (count != null) {
        return `repeat(${count}, 1fr)`;
    }

    return null;
};

export { getValidChildren, countToColumns };
