import moment from "moment";

import { EatingOption, Menu } from "Types";
import { activeHoursProducts, getHoursAndMinutes, getMergedActiveHoursByEatingOption } from "Utils";
import { MIDNIGHT_HOURS, timeConstants } from "Constants";
import { toMoment } from "../dateHelpers/dateHelpers";

type Pickup = {
    date: string;
    time: string;
};

export const getActiveMenus = (
    menus: Menu[],
    minMins: number,
    selectedPickup: Pickup,
    shopIsClosed: boolean,
    closeOnline: boolean,
    backendDiff: number
) => {
    const noSelectedPickup = !selectedPickup || (selectedPickup.date === "" && selectedPickup.time === "");

    const pickup = noSelectedPickup
        ? {
              date: moment().format("YYYY-MM-DD"),
              time: timeConstants.ASAP
          }
        : selectedPickup;

    const momentDate =
        pickup && pickup.time === timeConstants.ASAP
            ? moment().add(minMins, "minutes").add(backendDiff, "ms")
            : toMoment(`${pickup.date} ${pickup.time}`);

    const dayBefore = momentDate.clone().subtract(1, "days");
    const pickupTime = pickup.time === timeConstants.ASAP ? moment().format("HH:mm") : pickup.time;
    const [yesterdayStopHour] = getHoursAndMinutes(pickupTime);

    const selectedDate = yesterdayStopHour < MIDNIGHT_HOURS ? dayBefore : momentDate;

    const formattedDate = selectedDate.clone().format("dddd").toUpperCase();

    if (shopIsClosed || closeOnline) return menus;

    const _menus = menus.map(menu => {
        if (menu.eatingOptions.length === 1 && menu.eatingOptions.includes(EatingOption.CATERING)) {
            const activeHours = getMergedActiveHoursByEatingOption(menus, EatingOption.CATERING);

            return {
                ...menu,
                activeHours
            };
        } else {
            return menu;
        }
    });

    return _menus
        .reduce((acc, nextMenu) => {
            const foundActiveHours = nextMenu.activeHours.find(activeHour => activeHour.dayOfWeek === formattedDate);

            if (foundActiveHours) {
                const { startingHour, stoppingHour } = foundActiveHours;
                const [startHour] = getHoursAndMinutes(startingHour);
                const [stopHour] = getHoursAndMinutes(stoppingHour);

                const baseTime = momentDate;
                const startingHourM = toMoment(`${selectedDate.format("YYYY-MM-DD")} ${startingHour}`);
                const stoppingHourM = toMoment(`${selectedDate.format("YYYY-MM-DD")} ${stoppingHour}`);

                if (stopHour < startHour) {
                    stoppingHourM.add(1, "day");
                }
                const isBetween = baseTime.isBetween(startingHourM, stoppingHourM, undefined, "[]");
                if (isBetween) {
                    // @ts-ignore
                    acc.push(nextMenu);
                }
            }
            return acc;
        }, [])
        .map((activeMenu: any) => {
            return {
                ...activeMenu,
                menuProductCategories: activeMenu.menuProductCategories.map((menuProductCategory: any) => {
                    return {
                        ...menuProductCategory,
                        menuProducts: activeHoursProducts(menuProductCategory.menuProducts, pickup),
                        menuBundleProducts: activeHoursProducts(menuProductCategory.menuBundleProducts, pickup)
                    };
                })
            };
        });
};
