import {
    Modification,
    OnlineProduct,
    Addon,
    OnlineFixedDiscount,
    DISCOUNT_TYPE,
    Modifications,
    OnlineMenu,
    LastOrderProduct,
    AddonsHashMapValue
} from "Types";
import { getMenuProductModificationsToUse, hasFullMenuFixedDiscount, hasProductOrCategoryFixedDiscount } from "Utils";
import { AvailableBundleItem } from "OnlineTypes";

/**
 * Matching product modifications with
 */
const getModifications = (
    productModifications?: Modifications | undefined | null,
    choosenModifications?: Modifications | undefined | null
) => {
    /** Just return no mods */
    if (!choosenModifications && !productModifications) {
        return {
            hasMatchingModsOrNoMods: true,
            selectedModifications: null,
            missingModifications: []
        };
    }

    const emptyModifications = { sizes: [], flavours: [] };
    const defaultValue = [] as any;

    const { sizes: lastOrderSizes = defaultValue, flavours: lastOrderFlavours = defaultValue } =
        choosenModifications || emptyModifications;

    const { sizes: productSizes = defaultValue, flavours: productFlavours = defaultValue } =
        productModifications || emptyModifications;

    /** if didn't have modifications - but added to menu product in menu   */
    if (!choosenModifications && (!!productSizes.length || !!productFlavours.length)) {
        return {
            hasMatchingModsOrNoMods: false,
            selectedModifications: null,
            missingModifications: []
        };
    }

    let cartProductModifications: any = {};
    let sizeOfCartProductMods = 0;
    let missingChoosenModifications: string[] = [];

    if (!!lastOrderSizes.length && !!productSizes.length) {
        const commonSize = productSizes.filter((productSize: Modification) =>
            lastOrderSizes.some((lastSize: Modification) => productSize.name === lastSize.name)
        );
        if (!!commonSize.length) {
            cartProductModifications.sizes = { ...commonSize[0] };
            sizeOfCartProductMods += 1;
        } else {
            const lastOrderSizeNames = lastOrderSizes.map((lastSize: Modification) => lastSize.name);
            missingChoosenModifications = [...missingChoosenModifications, ...lastOrderSizeNames];
        }
    }

    if (!!lastOrderFlavours.length && !!productFlavours.length) {
        const commonFlavour = productFlavours.filter((productFlavour: Modification) =>
            lastOrderFlavours.some((lastFlavour: Modification) => productFlavour.name === lastFlavour.name)
        );
        if (!!commonFlavour.length) {
            cartProductModifications.flavours = { ...commonFlavour[0] };
            sizeOfCartProductMods += 1;
        } else {
            const lastOrderFlavourNames = lastOrderFlavours.map((lastFlavour: Modification) => lastFlavour.name);
            missingChoosenModifications = [...missingChoosenModifications, ...lastOrderFlavourNames];
        }
    }

    const sizeOfLastOrderMods: number = (lastOrderFlavours?.length ?? 0) + (lastOrderSizes?.length ?? 0);

    const hasMatchingMods = sizeOfCartProductMods === sizeOfLastOrderMods;

    return {
        hasMatchingModsOrNoMods: hasMatchingMods,
        selectedModifications: sizeOfLastOrderMods > 0 ? cartProductModifications : null,
        missingModifications: missingChoosenModifications
    };
};

/**
 * [FUNCTION] Helper to get menu product modifications
 * @param menuProduct
 * @param lastOrderModifications
 * @returns
 */
export const getLastOrderMenuProductModifications = (
    menuProduct: OnlineProduct,
    lastOrderModifications?: Modifications | undefined | null
) => {
    const menuProductModifications = getMenuProductModificationsToUse(menuProduct);
    return getModifications(menuProductModifications, lastOrderModifications);
};

/**
 * [FUNCTION] Helper to get modifications on selected bundle
 * @param bundleItem
 * @param selectedBundleModifications
 * @returns
 */
export const getLastOrderSelectedBundleModifications = (
    bundleItem?: AvailableBundleItem,
    selectedBundleModifications?: Modifications | undefined | null
) => {
    /** Due to the work around on bundle items + mods (where you can add a space in product library + price in the menu part) */
    const hasModificationsToOverride = !!bundleItem?.modificationsToUse;
    return getModifications(
        hasModificationsToOverride ? bundleItem?.modificationsToUse : bundleItem?.modifications,
        selectedBundleModifications
    );
};

/**
 * [FUNCTION] helper to get addons from last order product
 * @param addons
 * @param lastOrderAddons
 * @returns
 */
export const getLastOrderProductAddons = (addons: AddonsHashMapValue | null, lastOrderAddons?: Addon[] | null) => {
    if (!lastOrderAddons || !addons) {
        return [];
    }

    const listOfIngredients = addons?.flatMap(addon => addon.ingredients);

    return lastOrderAddons.reduce((newOrderAddons: Addon[], lastOrderAddon: Addon) => {
        const addonPresent = listOfIngredients?.find(list => list.ingredient.name === lastOrderAddon.name);
        if (addonPresent) {
            const newAddon: Addon = {
                ...lastOrderAddon,
                price: addonPresent.ingredient.price,
                groupId: addonPresent.ingredient.groupId
            };
            newOrderAddons = [...newOrderAddons, newAddon];
        }
        return newOrderAddons;
    }, []);
};

/**
 * [FUNCTION] helper get online discount (subscription or fixed discount)
 * @param menuId
 * @param menuCategoryId
 * @param onlineProduct
 * @param activeFixedDiscounts
 * @returns
 */
export const getLastOrderOnlineDiscount = (
    menuId: string,
    menuCategoryId: string,
    onlineProduct: OnlineProduct,
    activeFixedDiscounts: OnlineFixedDiscount[]
) => {
    if (onlineProduct.subscriptionProductMeta) {
        return {
            menuId: menuId,
            fixedDiscountId: onlineProduct.subscriptionProductMeta!.subscriptionId,
            menuCategoryId: menuCategoryId,
            menuProductId: onlineProduct.id,
            isFixedDiscountActive: true,
            canCombineDiscounts: false,
            type: DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT,
            subscriptionProductMeta: onlineProduct.subscriptionProductMeta
        } as OnlineFixedDiscount;
    } else {
        /* Get menu fixed discount */
        const menuFixedDiscount = activeFixedDiscounts.find(discount => {
            return discount.menuCategoryAndProductIds.some(menuIds => {
                return menuIds.menuId === menuId;
            });
        });

        /** If available check if full or specific fixed discount */
        if (!!menuFixedDiscount) {
            const fullMenuDisount = hasFullMenuFixedDiscount(menuId, menuFixedDiscount);
            const productOrCategoryDiscount = hasProductOrCategoryFixedDiscount(
                menuId,
                menuCategoryId,
                onlineProduct.id,
                menuFixedDiscount
            );
            const hasFixedDiscount = fullMenuDisount ? true : productOrCategoryDiscount;
            if (hasFixedDiscount) {
                return {
                    menuId: menuId,
                    fixedDiscountId: hasFixedDiscount ? menuFixedDiscount.id : "",
                    menuCategoryId: menuCategoryId,
                    menuProductId: onlineProduct.id,
                    isFixedDiscountActive: hasFixedDiscount,
                    canCombineDiscounts: hasFixedDiscount ? menuFixedDiscount?.combineWithOtherDiscounts : true,
                    type: DISCOUNT_TYPE.FIXED_DISCOUNT
                } as OnlineFixedDiscount;
            }
        }
    }
    return null;
};

/**
 * [FUNCTION] get missing adons on last order product
 */
export const getLastOrderAddonsMissing = (currentAddons: Addon[], lastOrderAddons: Addon[]) => {
    if (!lastOrderAddons.length) {
        return [];
    }

    /** Get Current addon names */
    const currentAddonNames = new Set(currentAddons?.map(addon => addon.name));

    /** If last order addons are not present in the current product return missing names */
    return lastOrderAddons.filter(item => !currentAddonNames.has(item.name))?.map(item => item.name) ?? [];
};

/**
 * [Function] Find image url from last order product - in current online menu
 * @param onlineMenu
 * @param lastOrderProduct
 * @returns
 */
export const getLastOrderImageUrl = (onlineMenu: OnlineMenu, lastOrderProduct: LastOrderProduct) => {
    const isMenuProduct = !!lastOrderProduct.refProductId;

    const productId = lastOrderProduct.refProductId || lastOrderProduct?.refBundleProductId!;

    /** Find product for urls */
    const foundProduct = onlineMenu.onlineProductCategories
        .map(cat =>
            cat.onlineProducts.find(prod => (isMenuProduct ? prod.refProduct : prod.refBundleProduct)?.id === productId)
        )
        .find(product => product !== undefined);

    /** if product is present return image url property */
    if (!!foundProduct) {
        return foundProduct.refProduct?.imageUrl || foundProduct.refBundleProduct?.imageUrl;
    }
    return null;
};
