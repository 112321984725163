/** Note: This is in the test suite */

import { CompanyLocale } from "Types";

// from pos/utils/priceUtils.js : roundNumber
export const roundNumber = (num: number) => Math.round(num * 100) / 100;

export const roundTo = (num: number, numDecimals: number): number => {
    return Number(num.toFixed(numDecimals));
};

/**
 * Proper rounding to two decimals if necessary and returns number
 * @param {number} num - the number to round
 * @return {number} formatted value
 */
export const roundToTwo = (num: number): number => {
    return roundTo(num, 2);
};

// Returns the number of decimal places for a given number
// https://stackoverflow.com/a/10454560
export const getNumDecimalPlaces = (num: number | string): number => {
    var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
        return 0;
    }

    // Number of digits right of decimal point.
    const numDigitsRightOfDecimal = match[1] ? match[1].length : 0;
    // Number of digits specified in scientific notation
    const scientificNotationDecimalPlaces = match[2] ? Math.abs(+match[2]) : 0;

    return Math.max(0, numDigitsRightOfDecimal, scientificNotationDecimalPlaces);
};

//Return percentage of a given number(denominator)
export const toPercentage = (numerator: number, denominator: number): number => {
    return denominator !== 0 ? roundTo((numerator / denominator) * 100, 2) : 0;
};

/**
 * [FUNCTION] - formats number (commas, decimals) into locale format
 * @param number 
 * @param locale 
 */
export const formatNumber = (number: number, locale: Intl.Locale | CompanyLocale) => {
    return new Intl.NumberFormat(locale.baseName).format(number);
} 