import { CountryLocales, SelectedCompany, CompanyLocale, RegionTimeZone } from "Types";

/**
 * [Function] to get CompanyLocale class and set timezone and currency with symbol
 * NOTE: timezone is incompatible with Fire fox needs to change when all browsers support it
 * NOTE: Only used for formatting financials and prices
 * @param {SelectedCompany} selectedCompany
 * @returns {CompanyLocale} new companyLocale
 * @example getCompanyLocale(selectedCompany) => new CompanyLocale()
 */
export const getCompanyLocale = (selectedCompany: SelectedCompany) => {
    const countryLocale = selectedCompany?.countryLocale?.localeCode
        ? selectedCompany.countryLocale
        : CountryLocales.SWEDEN;
    //@ts-ignore
    const companyLocale = new CompanyLocale(countryLocale?.localeCode);
    //@ts-ignore
    companyLocale.setTimeZone(RegionTimeZone[companyLocale.region as keyof typeof RegionTimeZone]);
    companyLocale.setCurrency(countryLocale?.currencyCode ?? "SEK");
    return companyLocale;
};

/**
 * [FUNCTION] to get country name by locale
 * @param locale
 * @returns
 * @example
 * 1. getNameOfCountryInNativeLanguage(new Intl.Locale("sv-SE")) => "Sverige"
 * 2. getNameOfCountryInNativeLanguage(new Intl.Locale("fi-FI")) => "Suomi"
 */
//@ts-ignore
export const getNameOfCountryInNativeLanguage = (locale: Intl.Locale) => {
    //@ts-ignore
    const displayName = new Intl.DisplayNames([locale.baseName], { type: "region" });
    //@ts-ignore
    return displayName.of(locale.region);
};
