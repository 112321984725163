const toggleFullScreen = (setFullscreen: (open: boolean) => void) => {
    const doc = window.document as any;
    const docEl = doc.documentElement as any;

    const requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen;
    const cancelFullScreen =
        doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
    ) {
        requestFullScreen.call(docEl);
        setFullscreen(true);
    } else {
        cancelFullScreen.call(doc);
        setFullscreen(false);
    }
};

function scrollToWithCallback(offset: number, callback: () => void) {
    const fixedOffset = offset.toFixed();
    const onScroll = function () {
        if (window.pageYOffset.toFixed() === fixedOffset) {
            window.removeEventListener("scroll", onScroll);
            callback();
        }
    };

    window.addEventListener("scroll", onScroll);
    onScroll();
    window.scrollTo({
        top: offset,
        behavior: "smooth"
    });
}

const isInStandalone = () => window.matchMedia("(display-mode: standalone)").matches;

export { toggleFullScreen, scrollToWithCallback, isInStandalone };
