import { ShopTags } from "Types";

export const computeTags = (tags: ShopTags) => {
	const generated = tags.generated;
	const always = tags.always;
	const never = tags.never;

	// Combine all tags
	const include = [
		...generated,
		...always,
	];
	const exclude = [
		...never,
	];

	// Remove duplicates
	const uniqueInclude = [...new Set(include)];
	const uniqueExclude = [...new Set(exclude)];

	// Remove excluded tags from included tags
	const computedTags = uniqueInclude.filter(tag => !uniqueExclude.includes(tag));

	return computedTags.sort();
}