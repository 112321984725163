/**
 *
 * @param {array} cartProducts - An array of cartproducts
 * @returns {number} - The total amount of all cartproducts
 */
import { divide, multiply } from "lodash";
import { getAddonPrice } from "../admin/utils/TextFormat";

/** Note: This is in the test suite */
export const getCartTotalPrice = cartProducts => {
    return cartProducts ? cartProducts.reduce((tot, { orderProduct }) => tot + orderProduct.totalPrice, 0) : 0;
};

// This will be deprecated when all POS:es are using the PosProvider
export const calculateTotalPrice = products => {
    return products.reduce((total, product) => total + product.totalPrice, 0);
};

// WARNING: Don't use this for new code, use instead:
// import { calculateBundleAddonPrice } from "PriceUtils";
export const calculateBundleProductAddonPrice = selectedBundleItems => {
    return selectedBundleItems.reduce((total, { addons }) => {
        return (total += getAddonPrice(addons));
    }, 0);
};

// Move these to "NumberUtils"
// src/utils/number/???
export const isNegative = number => Math.sign(number) === -1;
export const isPositive = number => Math.sign(number) === 1;

export const findOnlineProductFixedSubscriptionDiscount = (fixedDiscounts, onlineProduct) => {
    return fixedDiscounts.find(({ menuCategoryAndProductIds, rate, amount }) => {
        return menuCategoryAndProductIds.some(({ productIds }) => {
            const hasId = productIds.includes(onlineProduct.subscriptionProductMeta.refProductId);

            const hasAmount = onlineProduct.subscriptionProductMeta?.amountDiscount === amount;
            const hasRate = onlineProduct.subscriptionProductMeta?.percentageDiscount === rate;

            return hasId && (hasAmount || hasRate);
        });
    });
};

export const findFixedDiscountByRefProductId = (fixedDiscounts, onlineProduct) => {
    return fixedDiscounts.find(({ menuCategoryAndProductIds }) =>
        menuCategoryAndProductIds.some(
            ({ productIds }) =>
                productIds.includes(onlineProduct.refProduct?.id) ||
                productIds.includes(onlineProduct.refBundleProduct?.id)
        )
    );
};

const divideNumber = divisor => dividend => divide(dividend, divisor);
export const fromFractionizedAmount = divideNumber(100);

const multiplyNumber = multiplicand => multiplier => multiply(multiplier, multiplicand);
export const toFractionizedAmount = multiplyNumber(100);
